import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import {Checkbox, Divider, Link, Menu, MenuItem} from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {alpha, styled} from '@mui/material/styles';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {UserContext} from "../App";
import {MainContext, PATH_EC2, PATH_EC2_V2, PATH_SNAPSHOT} from "../Main";
import IssueDialog from "./IssueDialog";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


export default function Ec2ToolbarV2({ filter, setFilter, serverName, setServerName, tags, properties, 
    changePropSelect, changeTagSelect, reload, terminateServer }) {
    const user = useContext(UserContext);
    const context = useContext(MainContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        const input = query.get('filter') || ''
        if (input.includes(",")) { // 여러 그룹에 속한경우
            let f = ""
            input.split(",").forEach(i => {
                const find = context.filter.filter(v => v.toUpperCase() === i.trim().toUpperCase())
                if (find.length > 0) f = f + (f === '' ? '' : ",") + find[0]
            })
            setFilter(f)
        } else {
            const find = context.filter.filter(v => v.toUpperCase() === input.toUpperCase())
            if( find.length > 0 ) setFilter(find[0])
            else setServerName(input)
        }
    }, [context.filter])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function changeGroup(value) {
        if (value === "") setFilter("")
        else if (filter.indexOf(value) > -1) setFilter(filter.replace(value, "").split(",").filter(g => g > "").join(","))
        else if (filter.length > 0) setFilter(filter + "," + value);
        else setFilter(value)
        //setAnchorEl(null);
    }

    function searchServer(e) {
        setServerName(e.target.value)
    }

    return (<>
        <Box sx={{ flexGrow: 1, marginY: 2 }}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={handleClick}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
                    >
                        <span className="text-info">{filter === "" ? "전체 서버" : filter}</span>

                        <span onClick={() => context.setPath(PATH_EC2)}>
                            <Link className="text-white fs-6 ms-3 me-3">서버 목록</Link></span>

                        <span>
                            <Link href="#" onClick={() => setDialogOpen(true)}
                                  target="feedback" className="text-white fs-6 me-3">이슈/의견</Link></span>
                        <span>
                            <Link href="https://pages.bwg.co.kr/system/Systems/user-manual/"
                                  target={"help"} className="text-white fs-6 me-3">도움말</Link></span>

                        <span onClick={() => context.setPath(PATH_EC2_V2)}>
                            {context.path === PATH_EC2_V2 && <span className='text-success'>서버 목록(v2)</span>}
                            {context.path !== PATH_EC2_V2 && <Link className="text-white fs-6 me-3">서버 목록(v2)</Link>}
                        </span>

                        {/*<span onClick={() => context.setPath(PATH_VOLUME)}>*/}
                        {/*    <Link className="text-white fs-6 ms-3 me-3">Volume</Link></span>*/}

                        <span onClick={() => context.setPath(PATH_SNAPSHOT)}>
                            <Link className="text-white fs-6 ms-3 me-3">Snapshot</Link></span>

                        {/*<span onClick={() => context.setPath(PATH_EIP)}>*/}
                        {/*    <Link className="text-white fs-6 ms-3 me-3">Elastic IP</Link></span>*/}
                    </Typography>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'server group list',
                        }}
                    >
                        <MenuItem key="1" onClick={() => changeGroup("")}>전체 서버</MenuItem>
                        {context.filter.map((value, idx) => <MenuItem key={"1-" + idx}
                            onClick={() => changeGroup(value)}>
                            <Checkbox checked={filter > '' && filter.includes(value)} />
                            {value}
                        </MenuItem>)}
                        <Divider key="2" />

                        {/*<MenuItem key="3" onClick={reload}>서버 목록 재조회</MenuItem>*/}
                        {/*<MenuItem key="4" onClick={filterRunning}>가동중 서버목록</MenuItem>*/}
                        {/*<MenuItem key="5" onClick={filterStoped}>정지된 서버목록</MenuItem>*/}
                        <MenuItem key="10" onClick={handleClose}>{user.name}</MenuItem>
                        <Divider key="11" />
                        <MenuItem key="12" onClick={() => window.location.href = "/logout"}>Logout</MenuItem>
                    </Menu>

                    {/* Action */}
                    <PopupState variant="popover" popupId="family-site-popup-menu" disableAutoFocus={true}>
                        {(popupState) => (
                            <React.Fragment>
                                <Button variant="contained" {...bindTrigger(popupState)} className="me-3 bg-light text-accent">
                                    Action
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {/*서버에서 사이트 목록을 조회 한다. application.yml에 설정? */}
                                    <MenuItem onClick={() => reload(true)}>서버 재조회</MenuItem>
                                    <MenuItem onClick={terminateServer}>서버 삭제</MenuItem>
                                </Menu>
                            </React.Fragment>
                        )}
                    </PopupState>

                    {/* EC2 속성 */}
                    <PopupState variant="popover" popupId="family-site-popup-menu" disableAutoFocus={true}>
                        {(popupState) => (
                            <React.Fragment>
                                <Button variant="contained" {...bindTrigger(popupState)} className="me-3 bg-warning">
                                    EC2 속성
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {/*서버에서 사이트 목록을 조회 한다. application.yml에 설정? */}
                                    {properties.map((item) =>
                                        <MenuItem key={item.key} onClick={() => changePropSelect(item)} >
                                            <Checkbox checked={item.checked} size="small" />
                                            {item.label}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </React.Fragment>
                        )}
                    </PopupState>

                    {/* Tags */}
                    <PopupState variant="popover" popupId="family-site-popup-menu" disableAutoFocus={true}>
                        {(popupState) => (
                            <React.Fragment>
                                <Button variant="contained" {...bindTrigger(popupState)} className="me-3 bg-warning">
                                    Tags
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {/*서버에서 사이트 목록을 조회 한다. application.yml에 설정? */}
                                    {tags.map((item) =>
                                        <MenuItem key={item.key} onClick={() => changeTagSelect(item)}>
                                            <Checkbox checked={item.checked} size="small"  />
                                            {item.label}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </React.Fragment>
                        )}
                    </PopupState>

                    {/* Family Site */}
                    <PopupState variant="popover" popupId="family-site-popup-menu" disableAutoFocus={true}>
                        {(popupState) => (
                            <React.Fragment>
                                <Button variant="contained" {...bindTrigger(popupState)} className="bg-success">
                                    Family sites
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {/*서버에서 사이트 목록을 조회 한다. application.yml에 설정? */}
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://www.bankwareglobal.com")}>Homepage</MenuItem>
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://storage.bwg.co.kr/oauth2/authorization/keycloak")}>Storage(s3
                                        저장소)</MenuItem>
                                    <MenuItem onClick={() => openPage(popupState, "https://oneid.bwg.co.kr")}>Finlab
                                        계정</MenuItem>
                                    <MenuItem onClick={() => openPage(popupState, "https://finlab.bwg.co.kr")}>Finlab
                                        포털</MenuItem>
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://git.bwg.co.kr")}>Gitlab</MenuItem>
                                    <MenuItem onClick={() => openPage(popupState, "https://cbpdocs.bwg.co.kr")}>CBP
                                        Docs.</MenuItem>
                                    <MenuItem onClick={() => openPage(popupState, "https://frm.bwg.co.kr")}>CBP/PF
                                        지원요청</MenuItem>
                                    <MenuItem onClick={() => openPage(popupState, "https://cbp.bwg.co.kr")}>CBP
                                        데모</MenuItem>
                                    <MenuItem onClick={() => openPage(popupState, "https://pf.bwg.co.kr/pf")}>PF
                                        데모</MenuItem>
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://pages.bwg.co.kr")}>Pages-Finlab</MenuItem>
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://pages.bwg.co.kr/savings")}>Pages-Savings</MenuItem>
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://pages.bwg.co.kr/afs")}>Pages-AFS</MenuItem>
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://pages.bwg.co.kr/universal")}>Pages-UBS</MenuItem>
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://pages.bwg.co.kr/gateway")}>Pages-CPG</MenuItem>
                                    <MenuItem onClick={() => openPage(popupState, "https://pages.bwg.co.kr/bxim")}>Pages-딜리버리
                                        방법론</MenuItem>
                                    <MenuItem
                                        onClick={() => openPage(popupState, "https://pages.bwg.co.kr/qa")}>Pages-QA</MenuItem>
                                </Menu>
                            </React.Fragment>
                        )}
                    </PopupState>

                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={searchServer}
                            value={serverName}
                        />
                    </Search>
                </Toolbar>
                {/*<div className="px-2 bg-light text-dark">*/}
                {/*    <FormControl component="fieldset" >*/}
                {/*        /!* EC2 속성 : 수정 불가 *!/*/}
                {/*        <FormGroup aria-label="position" row>*/}
                {/*            {properties.map((item) =>*/}
                {/*                <FormControlLabel key={item.key} label={item.label} value={item.key}*/}
                {/*                    control={<Checkbox checked={item.checked} size="small"*/}
                {/*                        onChange={() => changePropSelect(item)} />} />*/}
                {/*            )}*/}
                {/*        </FormGroup>*/}
                {/*        /!* EC2 태그 : 수정 가능 - 일관 수정 *!/*/}
                {/*        <FormGroup aria-label="position" row>*/}
                {/*            {tags.map((item) =>*/}
                {/*                <FormControlLabel key={item.key} label={item.label} value={item.key}*/}
                {/*                    control={<Checkbox checked={item.checked} size="small"*/}
                {/*                        onChange={() => changeTagSelect(item)} />} />*/}
                {/*            )}*/}
                {/*            {user.authority === 'ADMIN' &&*/}
                {/*            <Button variant="text" color="primary" size={"small"} onClick={terminateServer}>서버 삭제</Button>}*/}
                {/*            <Button variant="text" color="primary" size={"small"} onClick={() => reload(true)}>서버 재조회</Button>*/}
                {/*        </FormGroup>*/}
                {/*    </FormControl>*/}
                {/*</div>*/}
            </AppBar>
        </Box>
        {dialogOpen && <IssueDialog setOpen={setDialogOpen} />}
    </>);

}

export function openPage(popupState, url) {
    window.open(url)
    popupState.close()
}
